/**
 * Global styles & font imports
 */

/* Load fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Manjari:300,400|Roboto:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

/* Disable user select */
body {
  user-select: none;
}

/* Remove spaces */
html,
body,
:root {
  padding: 0;
  margin: 0;
}

:not(a),
:not(a > *) {
  cursor: default !important;
}

/* Disable touch highlight */
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}