.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  overflow: hidden;

  background-image: url('/images/home_background.png');
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;

  transition: opacity 0.3s 0.5s ease-out, visibility 0.8s;
  opacity: 1;
  visibility: visible;
}

.FadeOut {
  opacity: 0;
  visibility: hidden;
}

.Card {
  position: absolute;
  width: 300px;
  height: 240px;
  left: calc(50% - 150px);
  top: 25%;
  overflow: hidden;

  background-color: white;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  transition: transform 0.3s 0.1s ease-in;
  transform: translateY(0) scale(1);
}

.FlyOut {
  transform: translateY(-500px) scale(0.6);
}

.Greeting {
  font-family: var(--primary-font);
  font-size: 48px;
  text-align: center;

  color: #333333;

  margin-bottom: 0;
}

.Welcome {
  font-family: var(--primary-font);
  font-size: 24px;
  text-align: center;
  font-weight: 300;

  color: #555;
  margin-top: 16px;
}

.Button {
  position: absolute !important;
  bottom: 10px;
  left: 10px;
  width: 280px;
  border-radius: 6px !important;
}
