@import url(https://fonts.googleapis.com/css?family=Manjari:300,400|Roboto:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
.Landing_Container__3F_rZ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  overflow: hidden;

  background-image: url('/images/home_background.png');
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;

  -webkit-transition: opacity 0.3s 0.5s ease-out, visibility 0.8s;

  transition: opacity 0.3s 0.5s ease-out, visibility 0.8s;
  opacity: 1;
  visibility: visible;
}

.Landing_FadeOut__3fGUe {
  opacity: 0;
  visibility: hidden;
}

.Landing_Card__2h_kR {
  position: absolute;
  width: 300px;
  height: 240px;
  left: calc(50% - 150px);
  top: 25%;
  overflow: hidden;

  background-color: white;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  -webkit-transition: -webkit-transform 0.3s 0.1s ease-in;

  transition: -webkit-transform 0.3s 0.1s ease-in;

  transition: transform 0.3s 0.1s ease-in;

  transition: transform 0.3s 0.1s ease-in, -webkit-transform 0.3s 0.1s ease-in;
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
}

.Landing_FlyOut__203rN {
  -webkit-transform: translateY(-500px) scale(0.6);
          transform: translateY(-500px) scale(0.6);
}

.Landing_Greeting__1hEaw {
  font-family: var(--primary-font);
  font-size: 48px;
  text-align: center;

  color: #333333;

  margin-bottom: 0;
}

.Landing_Welcome__1t99V {
  font-family: var(--primary-font);
  font-size: 24px;
  text-align: center;
  font-weight: 300;

  color: #555;
  margin-top: 16px;
}

.Landing_Button__1Ec-X {
  position: absolute !important;
  bottom: 10px;
  left: 10px;
  width: 280px;
  border-radius: 6px !important;
}

/**
 * Global styles & font imports
 */

/* Load fonts from Google Fonts */

/* Disable user select */
body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Remove spaces */
html,
body,
:root {
  padding: 0;
  margin: 0;
}

:not(a),
:not(a > *) {
  cursor: default !important;
}

/* Disable touch highlight */
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
